import React from 'react';

const MobileFeasibility = () => {
    return (
        <div style={styles.container}>
            <h2>Mobile Feasibility</h2>
            <p>Your mobile feasibility results will be displayed here...</p>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        textAlign: 'center',
    },
};

export default MobileFeasibility;
