import React from 'react';

const FibreFeasibility = () => {
    return (
        <div style={styles.container}>
            <h2>Fibre Feasibility</h2>
            <p>Your fibre feasibility results will be displayed here...</p>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        textAlign: 'center',
    },
};

export default FibreFeasibility;
