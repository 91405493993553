import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaWifi, FaNetworkWired, FaMobileAlt, FaCog, FaSearch } from 'react-icons/fa';
import WirelessFeasibility from './WirelessFeasibility';
import FibreFeasibility from './FibreFeasibility';
import MobileFeasibility from './MobileFeasibility';
import PreviousResults from './PreviousResults';
import ManageSettings from './ManageSettings';

const GOOGLE_API_KEY = 'AIzaSyAuC39UoplUhw-bFf1kcBegnm7j5RUvHhg';

function Main() {
    const navigate = useNavigate();
    const [selectedView, setSelectedView] = useState('wireless');
    const [frequency, setFrequency] = useState('');
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [address, setAddress] = useState('');
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
    const [maxDistance, setMaxDistance] = useState(7000);
    const [error, setError] = useState('');
    const [losResults, setLosResults] = useState(null);  // Add this to your state


    useEffect(() => {
        const token = localStorage.getItem('token');
        console.log('Token loaded from localStorage:', token);
        const role = localStorage.getItem('userRole');
        console.log('Role loaded from localStorage:', role);

        if (!token) {
            console.warn('No token found. Redirecting to login...');
            navigate('/login');
        } else {
            setUserRole(role);
            fetchUserName(token);
        }

        loadGoogleAPIs(() => {
            console.log('Google APIs loaded. Initializing Autocomplete...');
            initAutocomplete();
        });
    }, [navigate]);

    const fetchUserName = async (token) => {
        try {
            console.log('Fetching user data with token:', token);
            const response = await fetch('/api/get-user', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log('User data received:', data);
                setUserName(data.name);
            } else if (response.status === 401) {
                // Token might be expired, handle refresh
                console.warn('Token expired, attempting to refresh...');
                const refreshed = await refreshToken();
                if (refreshed) {
                    fetchUserName(localStorage.getItem('token')); // Retry with refreshed token
                } else {
                    navigate('/login'); // If refresh fails, force login
                }
            } else {
                console.error('Failed to fetch user name. Status:', response.statusText);
                setError('Failed to fetch user name');
            }
        } catch (error) {
            console.error('Error fetching user name:', error);
            setError('Error fetching user name');
        }
    };
    
    const refreshToken = async () => {
        try {
            const refreshToken = localStorage.getItem('refreshToken');
            const response = await fetch('/api/refresh-token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: refreshToken }),
            });
    
            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('token', data.accessToken);
                console.log('Token refreshed successfully:', data.accessToken);
                return true;
            } else {
                console.error('Failed to refresh token. Status:', response.statusText);
                return false;
            }
        } catch (error) {
            console.error('Error refreshing token:', error);
            return false;
        }
    };
    

    const loadGoogleAPIs = (callback) => {
        if (!window.google) {
            console.log('Google Maps API not loaded. Appending script tag...');
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;  // Removed visualization
            script.async = true;
            script.defer = true;
            script.onload = () => {
                console.log('Google Maps API script loaded.');
                callback();
            };
            document.body.appendChild(script);
        } else {
            console.log('Google Maps API already loaded.');
            callback();
        }
    };
    

    const handleCoordinateChange = (e) => {
        const value = e.target.value;
        console.log('Handling manual coordinate input:', value);
        const [lat, lng] = value.split(',').map(coord => parseFloat(coord.trim()));
        if (!isNaN(lat) && !isNaN(lng)) {
            setCoordinates({ lat, lng });
            console.log('Coordinates set via manual input:', { lat, lng });
            setError('');  // Clear error if valid coordinates are entered
        } else {
            console.error('Invalid coordinate format entered:', value);
            setError('Invalid coordinate format. Use "lat, lng" format.');
        }
    };

    const handleMaxDistanceChange = (e) => {
        const value = parseFloat(e.target.value);
        console.log('Max distance changed to:', value);
        if (value <= 10000 && value > 0) {
            setMaxDistance(value);
        } else {
            console.warn('Max Distance exceeds limit or invalid value:', value);
            setError('Max Distance cannot exceed 10,000 meters.');
        }
    };

    const handleFrequencyChange = (e) => {
        const value = e.target.value;
        console.log('Frequency changed to:', value);
        setFrequency(value);
    };

    const handleProcessFeasibility = async () => {
        if (coordinates.lat === null || coordinates.lng === null) {
            console.warn('No coordinates provided for LOS calculation.');
            setError('Start point required for LOS calculation.');
            return;
        }
    
        try {
            console.log('Triggering LOS calculation with:', {
                coordinates,
                maxDistance,
                frequency
            });
    
            const response = await fetch('/api/calculate-los', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    origin: coordinates,
                    maxDistance,
                    frequency
                }),
            });
    
            if (!response.ok) {
                throw new Error(`LOS calculation failed: ${response.statusText}`);
            }
    
            const losData = await response.json();
            console.log('LOS calculation result:', losData);
    
            // Store the LOS results
            setLosResults(losData);  // Set the state with the fetched LOS data
    
        } catch (error) {
            console.error('Error during LOS calculation:', error);
            setError('Error during LOS calculation');
        }
    };
    
    
    

    const handlePlaceSelect = (place) => {
        if (place.geometry) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            setCoordinates({ lat, lng }); // Ensure coordinates are set correctly
            console.log('Coordinates set via Autocomplete:', { lat, lng });
    
            // Automatically trigger LOS calculation after setting the coordinates
            handleProcessFeasibility(); 
        } else {
            console.error('No geometry returned for selected place.');
            setError('Selected place does not have valid geometry data.');
        }
    };
    
    

    const initAutocomplete = () => {
        if (window.google && document.getElementById('autocomplete')) {
            console.log('Initializing Google Places Autocomplete...');
            const autocomplete = new window.google.maps.places.Autocomplete(
                document.getElementById('autocomplete'),
                { types: ['geocode'] }
            );

            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (place.geometry) {
                    handlePlaceSelect(place);
                } else {
                    console.error('No geometry returned for selected place.');
                }
            });
        }
    };

    const renderMainContent = () => {
        switch (selectedView) {
            case 'wireless':
                return (
                    <WirelessFeasibility 
                        coordinates={coordinates} 
                        maxDistance={maxDistance} 
                        frequency={frequency} 
                        losResults={losResults} // Pass LOS results to WirelessFeasibility component
                    />
                );
            case 'fiber':
                return <FibreFeasibility />;
            case 'mobile':
                return <MobileFeasibility />;
            case 'results':
                return <PreviousResults />;
            case 'settings':
                return <ManageSettings />;
            default:
                return <p>Select an option above to view results.</p>;
        }
    };
    

    return (
        <div style={styles.pageContainer}>
            <div style={styles.topBar}>
                <div style={styles.topLeft}>
                    <div style={styles.iconContainer} onClick={() => setSelectedView('settings')}>
                        <FaCog style={styles.icon} />
                        <span style={styles.iconText}>Manage<br />System</span>
                    </div>
                </div>
                <div style={styles.iconWrapper}>
                    <div style={styles.iconContainer} onClick={() => setSelectedView('wireless')}>
                        <FaWifi style={styles.icon} />
                        <span style={styles.iconText}>Wireless<br />Feasibility</span>
                    </div>
                    <div style={styles.iconContainer} onClick={() => setSelectedView('fiber')}>
                        <FaNetworkWired style={styles.icon} />
                        <span style={styles.iconText}>Fibre<br />Feasibility</span>
                    </div>
                </div>
                <div style={styles.logoContainer}>
                    <img src="smartmaplogotext.png" alt="SmartMap Logo" style={styles.logo} />
                </div>
                <div style={styles.iconWrapper}>
                    <div style={styles.iconContainer} onClick={() => setSelectedView('mobile')}>
                        <FaMobileAlt style={styles.icon} />
                        <span style={styles.iconText}>Mobile<br />Feasibility</span>
                    </div>
                    <div style={styles.iconContainer} onClick={() => setSelectedView('results')}>
                        <FaSearch style={styles.icon} />
                        <span style={styles.iconText}>Previous<br />Results</span>
                    </div>
                </div>
            </div>

            <div style={styles.mainContent}>
                <div style={styles.leftBar}>
                    <h3 style={styles.userDetails}>
                        {userName} ({userRole})
                    </h3>
                    <h3 style={styles.leftBarTitle}>Location Details</h3>
                    <input
                        id="autocomplete"
                        type="text"
                        placeholder="Search Address..."
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        style={styles.input}
                    />
                    <p style={styles.orText}>or</p>
                    <input
                        type="text"
                        placeholder="Input Coordinates (lat, lng)..."
                        onChange={handleCoordinateChange}
                        style={styles.input}
                    />

                    <p style={styles.orText}>Max Distance to Target (meters)</p>
                    <input
                        type="number"
                        value={maxDistance}
                        onChange={handleMaxDistanceChange}
                        style={styles.input}
                    />

                    <p style={styles.orText}>Frequency</p>
                    <select value={frequency} onChange={handleFrequencyChange} style={styles.input}>
                        <option value="">Select Frequency</option>
                        <option value="2GHz">2GHz</option>
                        <option value="5GHz">5GHz</option>
                        <option value="10GHz">10GHz</option>
                        <option value="24GHz">24GHz</option>
                        <option value="60GHz">60GHz</option>
                        <option value="80GHz">80GHz</option>
                    </select>

                    <button onClick={handleProcessFeasibility} style={styles.processButton}>Process Feasibility</button>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>

                <div style={styles.mainArea}>
                    {renderMainContent()}
                </div>
            </div>
        </div>
    );
}

const styles = {
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        position: 'relative',
    },
    topBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 40px',
        backgroundColor: '#fff',
        color: '#333',
        position: 'fixed',
        width: '100%',
        top: 0,
        zIndex: 1000,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    topLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flex: 0.5,
    },
    logoContainer: {
        flex: 1,
        textAlign: 'center',
    },
    logo: {
        height: '45px',
    },
    iconWrapper: {
        display: 'flex',
        flex: 2,
        justifyContent: 'space-evenly',
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
    },
    icon: {
        fontSize: '28px',
        color: '#61acb4',
    },
    iconText: {
        fontSize: '14px',
        marginTop: '5px',
        textAlign: 'center',
        color: 'slategray',
    },
    mainContent: {
        display: 'flex',
        flex: 1,
        marginTop: '110px',
    },
    leftBar: {
        width: '25%',
        backgroundColor: '#fff',
        padding: '20px',
        borderRight: '1px solid #ddd',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    leftBarTitle: {
        marginBottom: '20px',
        fontSize: '18px',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        border: '1px solid #ddd',
        borderRadius: '5px',
    },
    orText: {
        margin: '10px 0',
        fontSize: '12px',
    },
    processButton: {
        padding: '10px',
        marginTop: '10px',
        backgroundColor: '#61acb4',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    mainArea: {
        flex: 1,
        padding: '20px',
        overflowY: 'auto',
    },
    userDetails: {
        fontSize: '12px',
        marginBottom: '18px',
    },
};

export default Main;
