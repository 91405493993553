import React, { useState, useEffect } from 'react';

const WirelessFeasibility = ({ coordinates, maxDistance, frequency }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [losResults, setLosResults] = useState(null); // Added this state declaration

    const calculateLOS = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('You must be logged in to calculate LOS.');
            return;
        }
    
        setLoading(true);
        setError(null);
    
        try {
            console.log('Sending LOS calculation request with:', {
                origin: coordinates,
                maxDistance,
                frequency,
            });
    
            const response = await fetch('/api/calculate-los', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    origin: coordinates,
                    maxDistance,
                    frequency,
                }),
            });
    
            if (response.ok) {
                const data = await response.json();
                setLosResults(data); // Set the results here
                console.log('LOS Results:', data);
            } else {
                const errorText = await response.text();
                console.error('Failed to fetch LOS results:', response.status, errorText);
                setError(`Failed to fetch LOS results: ${response.status}`);
            }
        } catch (error) {
            console.error('Error in calculateLOS:', error);
            setError('An error occurred while calculating LOS.');
        } finally {
            setLoading(false);
        }
    };

    const renderResults = () => {
        return losResults.map((result, index) => (
            <div key={index} style={styles.resultItem}>
                <h3>Base Station: {result.baseStation.name}</h3>
                <p>Distance: {result.distance} meters</p>
                <p>Bearing: {result.bearing}° (Magnetic North)</p>
                <p>Elevation Angle: {result.elevationAngle ? `${result.elevationAngle}°` : 'N/A'}</p>
                <p>LOS Status: {result.losStatus}</p>
                <a href={result.elevationProfileUrl} target="_blank" rel="noopener noreferrer">
                    View Elevation Profile
                </a>
            </div>
        ));
    };

    return (
        <div style={styles.container}>
            <h1>Wireless Feasibility</h1>
            {loading && <p>Calculating LOS, please wait...</p>}
            {error && <div style={styles.error}>{error}</div>}
            {losResults && (
                <div style={styles.resultsContainer}>
                    <h2>LOS Results</h2>
                    {renderResults()}
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
    },
    error: {
        color: 'red',
        marginTop: '10px',
    },
    resultsContainer: {
        marginTop: '20px',
    },
    resultItem: {
        padding: '10px',
        border: '1px solid #ddd',
        marginBottom: '10px',
    },
};

export default WirelessFeasibility;
