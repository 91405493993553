import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBars, FaHome, FaSignInAlt, FaBox, FaEnvelope } from 'react-icons/fa';

function LandingPage() {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setMenuOpen(false);
    };

    return (
        <div style={styles.container}>
            <header style={styles.header}>
                <FaBars style={styles.menuIcon} onClick={toggleMenu} />
                <img src="/logosmartmap.png" alt="SmartMap Logo" style={styles.logo} />
            </header>
            <h1 style={styles.tagline}>Seeing where you cannot</h1>
            {menuOpen && (
                <div style={styles.menu}>
                    <ul style={styles.menuList}>
                        <li onClick={() => handleNavigation('/')} style={styles.menuItem}>
                            <FaHome style={styles.icon} /> Home
                        </li>
                        <li onClick={() => handleNavigation('/login')} style={styles.menuItem}>
                            <FaSignInAlt style={styles.icon} /> Smartmap Login
                        </li>
                        <li onClick={() => handleNavigation('/products')} style={styles.menuItem}>
                            <FaBox style={styles.icon} /> Our Products
                        </li>
                        <li onClick={() => handleNavigation('/contact')} style={styles.menuItem}>
                            <FaEnvelope style={styles.icon} /> Contact Us
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
}

const styles = {
    container: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        width: '90%',
        margin: '0 auto',
        paddingTop: '20px', // Added padding to move content slightly down from the top
        overflow: 'hidden',
    },
    header: {
        position: 'relative',
        width: '100%',
        textAlign: 'center',
    },
    menuIcon: {
        position: 'absolute',
        left: '20px',
        top: '20px',
        cursor: 'pointer',
        fontSize: '24px',
        color: 'black',
    },
    logo: {
        maxWidth: '300px',
        width: '100%',
        height: 'auto',
        marginBottom: '20px',
    },
    tagline: {
        fontSize: '1.5rem',
        color: '#333',
        marginTop: '20px',
    },
    menu: {
        position: 'absolute',
        top: '50px',
        left: '20px',
        width: '250px',
        backgroundColor: '#f8f9fa',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: '1000',
    },
    menuList: {
        listStyleType: 'none',
        padding: '0',
        margin: '0',
    },
    menuItem: {
        padding: '15px 20px',
        borderBottom: '1px solid #ddd',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        color: '#333',
    },
    icon: {
        marginRight: '10px',
    },
};

export default LandingPage;
