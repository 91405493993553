import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap'; // Import Button component for consistent styling
import './ManageProviders.css'; // Assuming a CSS file for consistent styling

function ManageProviders() {
    const [providers, setProviders] = useState([]);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchProviders();
    }, []);

    const fetchProviders = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('/api/get-providers', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch providers: ${response.statusText}`);
            }
            const data = await response.json();

            // Adding company base stations as a provider
            const companyBaseStations = { id: 'company_base_stations', name: `${data.companyName} Base Stations` };
            const allProviders = [companyBaseStations, ...data.providers];
            setProviders(allProviders);

            // Tick base stations by default
            setSelectedProviders([companyBaseStations.id, ...data.selectedProviders]);

        } catch (error) {
            console.error('Error fetching providers:', error);
            setMessage('Error fetching providers.');
        }
    };

    const handleProviderSelection = (providerId) => {
        setSelectedProviders((prevSelected) => {
            if (prevSelected.includes(providerId)) {
                return prevSelected.filter(id => id !== providerId);
            } else {
                return [...prevSelected, providerId];
            }
        });
    };

    const handleSaveSelection = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('/api/update-selected-providers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ selectedProviders }),
            });

            if (!response.ok) {
                throw new Error(`Failed to save selected providers: ${response.statusText}`);
            }

            const result = await response.json();
            setMessage(result.message || 'Providers selection updated successfully.');
        } catch (error) {
            console.error('Error saving providers selection:', error);
            setMessage('Error saving providers selection.');
        }
    };

    return (
        <div className="manage-providers-container">
            <div className="heading-bar">
                <h3 className="page-title">Manage Providers</h3>
            </div>

            {providers.length === 0 ? (
                <p>No providers found.</p>
            ) : (
                <div className="providers-list-container">
                    <ul className="providers-list">
                        {providers.map((provider) => (
                            <li key={provider.id} className="provider-item">
                                <label className="provider-label">
                                    <input
                                        type="checkbox"
                                        checked={selectedProviders.includes(provider.id)}
                                        onChange={() => handleProviderSelection(provider.id)}
                                    />
                                    {provider.name}
                                </label>
                            </li>
                        ))}
                    </ul>
                    <Button onClick={handleSaveSelection} className="save-selection-button">
                        Save Selection
                    </Button>
                </div>
            )}
            {message && <p className="message-text">{message}</p>}
        </div>
    );
}


export default ManageProviders;
