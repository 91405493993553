import React, { useState, useEffect } from 'react';
import { FaBroadcastTower, FaNetworkWired, FaBuilding, FaMapMarkerAlt } from 'react-icons/fa';
import ManageProviders from './ManageProviders';
import ManageUsers from './ManageUsers';
import ManageCompanies from './ManageCompanies';
import ManageBaseStations from './ManageBaseStations';

function ManageSettings() {
    const [selectedSection, setSelectedSection] = useState(null);
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        const role = localStorage.getItem('userRole');
        console.log('User Role from localStorage:', role); // Debugging line to see what is being retrieved
        if (role) {
            setUserRole(role);
        } else {
            console.error('No user role found in localStorage'); // Handle the case where no role is found
        }
    }, []);
    

    const renderSection = () => {
        switch (selectedSection) {
            case 'providers':
                return <ManageProviders />;
            case 'users':
                return <ManageUsers />;
            case 'companies':
                return userRole === 'superadmin' && <ManageCompanies />;
            case 'baseStations':
                return <ManageBaseStations />;
            default:
                return <p>Select a section to manage.</p>;
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.iconWrapper}>
                <div style={styles.iconContainer} onClick={() => setSelectedSection('providers')}>
                    <FaBroadcastTower style={styles.icon} />
                    <span style={styles.iconText}>Manage<br />Providers</span>
                </div>
                <div style={styles.iconContainer} onClick={() => setSelectedSection('users')}>
                    <FaNetworkWired style={styles.icon} />
                    <span style={styles.iconText}>Manage<br />Users</span>
                </div>
                {userRole === 'superadmin' && (
                    <div style={styles.iconContainer} onClick={() => setSelectedSection('companies')}>
                        <FaBuilding style={styles.icon} />
                        <span style={styles.iconText}>Manage<br />Companies</span>
                    </div>
                )}
                <div style={styles.iconContainer} onClick={() => setSelectedSection('baseStations')}>
                    <FaMapMarkerAlt style={styles.icon} />
                    <span style={styles.iconText}>Manage<br />Base Stations</span>
                </div>
            </div>
            <div style={styles.settingsContent}>
                {renderSection()}
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '20px',
        cursor: 'pointer',
    },
    icon: {
        fontSize: '28px',
        color: '#61acb4',
    },
    iconText: {
        fontSize: '14px',
        marginTop: '5px',
        textAlign: 'center',
        color: 'slategray',
    },
    settingsContent: {
        width: '80%',
        textAlign: 'left',
    },
};

export default ManageSettings;
