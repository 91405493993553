import React, { useState } from 'react';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch('/api/forgot-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            setMessage('If your email is registered, you will receive a password reset link.');
            setError('');
        } else {
            setError('There was an issue processing your request. Please try again.');
            setMessage('');
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.forgotPasswordBox}>
                <div style={styles.logoSection}>
                    <img src="/smartmaplogotext.png" alt="SmartMap Logo" style={styles.logo} />
                </div>
                <div style={styles.formSection}>
                    <h3 style={styles.heading}>Forgot Password</h3>
                    <form onSubmit={handleSubmit} style={styles.form}>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={styles.input}
                        />
                        <button type="submit" style={styles.button}>Send Reset Link</button>
                    </form>
                    {message && <div style={styles.message}>{message}</div>}
                    {error && <div style={styles.error}>{error}</div>}
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#ecf0f1',
    },
    forgotPasswordBox: {
        display: 'flex',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '40px',
        maxWidth: '800px',
        width: '100%',
    },
    logoSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        paddingRight: '20px',
        borderRight: '1px solid #bdc3c7',
    },
    logo: {
        maxWidth: '150px',
        width: '100%',
        height: 'auto',
    },
    formSection: {
        flex: 2,
        paddingLeft: '20px',
    },
    heading: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#2c3e50',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    input: {
        backgroundColor: '#ecf0f1',
        border: '1px solid #1abc9c',
        borderRadius: '4px',
        padding: '15px',
        marginBottom: '20px',
        color: '#2c3e50',
        fontSize: '16px',
    },
    button: {
        backgroundColor: '#1abc9c',
        border: 'none',
        borderRadius: '4px',
        padding: '15px',
        color: 'white',
        fontSize: '16px',
        cursor: 'pointer',
    },
    message: {
        marginTop: '20px',
        textAlign: 'center',
        color: '#27ae60',
    },
    error: {
        marginTop: '20px',
        textAlign: 'center',
        color: 'red',
    },
};

export default ForgotPassword;
