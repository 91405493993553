import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import './ManageCompanies.css';

function ManageCompanies() {
    const [companies, setCompanies] = useState([]);
    const [newCompany, setNewCompany] = useState({ name: '', contact_person: '', mobile: '', email: '', billing_details: '', username: '', password: '' });
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('/api/get-companies', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch companies: ${response.statusText}`);
            }

            const data = await response.json();
            setCompanies(data);
        } catch (error) {
            console.error('Error fetching companies:', error);
            setMessage('Error fetching companies.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewCompany({ ...newCompany, [name]: value });
    };

    const handleAddCompany = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('/api/add-company', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(newCompany),
            });

            if (!response.ok) {
                throw new Error(`Failed to add company: ${response.statusText}`);
            }

            const result = await response.json();
            setMessage(result.message);
            fetchCompanies();
            setNewCompany({ name: '', contact_person: '', mobile: '', email: '', billing_details: '', username: '', password: '' });
        } catch (error) {
            console.error('Error adding company:', error);
            setMessage('Error adding company.');
        }
    };

    return (
        <div className="manage-companies-container">
            <div className="heading-bar">
                <h3 className="page-title">Manage Companies</h3>
            </div>

            {message && <p className="message-text">{message}</p>}

            {companies.length === 0 ? (
                <p>No companies found. Please add a new company.</p>
            ) : (
                <table className="companies-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Contact Person</th>
                            <th>Mobile</th>
                            <th>Email</th>
                            <th>Billing Details</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {companies.map((company) => (
                            <tr key={company.id}>
                                <td>{company.name}</td>
                                <td>{company.contact_person}</td>
                                <td>{company.mobile}</td>
                                <td>{company.email}</td>
                                <td>{company.billing_details}</td>
                                <td>
                                    {/* Add Edit and Delete functionality here */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            <div className="add-company-container">
                <Button
                    variant="primary"
                    onClick={handleAddCompany}
                    className="add-company-button"
                >
                    Add Company
                </Button>

                <div className="add-company-form">
                    <Form>
                        <Form.Group controlId="formName">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={newCompany.name}
                                onChange={handleInputChange}
                                placeholder="Enter company name"
                                style={styles.input}
                            />
                        </Form.Group>

                        <Form.Group controlId="formContactPerson">
                            <Form.Label>Contact Person</Form.Label>
                            <Form.Control
                                type="text"
                                name="contact_person"
                                value={newCompany.contact_person}
                                onChange={handleInputChange}
                                placeholder="Enter contact person"
                                style={styles.input}
                            />
                        </Form.Group>

                        <Form.Group controlId="formMobile">
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control
                                type="text"
                                name="mobile"
                                value={newCompany.mobile}
                                onChange={handleInputChange}
                                placeholder="Enter mobile number"
                                style={styles.input}
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={newCompany.email}
                                onChange={handleInputChange}
                                placeholder="Enter email"
                                style={styles.input}
                            />
                        </Form.Group>

                        <Form.Group controlId="formUsername">
                            <Form.Label>Admin Username</Form.Label>
                            <Form.Control
                                type="text"
                                name="username"
                                value={newCompany.username}
                                onChange={handleInputChange}
                                placeholder="Enter username for admin user"
                                style={styles.input}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBillingDetails">
                            <Form.Label>Billing Details</Form.Label>
                            <Form.Control
                                type="text"
                                name="billing_details"
                                value={newCompany.billing_details}
                                onChange={handleInputChange}
                                placeholder="Enter billing details"
                                style={styles.input}
                            />
                        </Form.Group>

                        <Form.Group controlId="formPassword">
                            <Form.Label>Admin Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                value={newCompany.password}
                                onChange={handleInputChange}
                                placeholder="Enter password for admin user"
                                style={styles.input}
                            />
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </div>
    );
}

const styles = {
    input: {
        backgroundColor: '#ecf0f1',
        border: '1px solid #1abc9c',
        borderRadius: '4px',
        padding: '15px',
        marginBottom: '20px',
        color: '#2c3e50',
        fontSize: '16px',
        width: '80%',
    },
};

export default ManageCompanies;
