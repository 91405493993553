import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const { token } = useParams();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setMessage('Passwords do not match.');
            return;
        }

        const response = await fetch('/api/reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token, password }),
        });

        if (response.ok) {
            setMessage('Password has been reset successfully.');
            setTimeout(() => navigate('/login'), 2000); // Redirect to login after 2 seconds
        } else {
            setMessage('Failed to reset password. Please try again.');
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.resetBox}>
                <div style={styles.logoSection}>
                    <img src="/smartmaplogotext.png" alt="SmartMap Logo" style={styles.logo} />
                </div>
                <div style={styles.formSection}>
                    <h3 style={styles.heading}>Reset Your Password</h3>
                    <form onSubmit={handleSubmit} style={styles.form}>
                        <input
                            type="password"
                            placeholder="New Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={styles.input}
                        />
                        <input
                            type="password"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            style={styles.input}
                        />
                        <button type="submit" style={styles.button}>Reset Password</button>
                    </form>
                    {message && <div style={styles.message}>{message}</div>}
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#ecf0f1',
    },
    resetBox: {
        display: 'flex',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '40px',
        maxWidth: '800px',
        width: '100%',
    },
    logoSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        paddingRight: '20px',
        borderRight: '1px solid #bdc3c7',
    },
    logo: {
        maxWidth: '150px',
        width: '100%',
        height: 'auto',
    },
    formSection: {
        flex: 2,
        paddingLeft: '20px',
    },
    heading: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#2c3e50',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    input: {
        backgroundColor: '#ecf0f1',
        border: '1px solid #1abc9c',
        borderRadius: '4px',
        padding: '15px',
        marginBottom: '20px',
        color: '#2c3e50',
        fontSize: '16px',
    },
    button: {
        backgroundColor: '#1abc9c',
        border: 'none',
        borderRadius: '4px',
        padding: '15px',
        color: 'white',
        fontSize: '16px',
        cursor: 'pointer',
    },
    message: {
        marginTop: '20px',
        textAlign: 'center',
        color: '#27ae60',
    },
};

export default ResetPassword;
