import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import './ManageUsers.css';

const ManageUsers = () => {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');
    const [showAddUserForm, setShowAddUserForm] = useState(false);
    const [newUser, setNewUser] = useState({ name: '', email: '', role: '' });

    useEffect(() => {
        const fetchUsers = async () => {
            const token = localStorage.getItem('token');
            const response = await fetch('/api/get-users', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                console.error('Failed to fetch users:', response.statusText);
                setError('Failed to fetch users.');
                return;
            }

            try {
                const data = await response.json();
                setUsers(data);
            } catch (err) {
                console.error('Error parsing users:', err);
                setError('Error parsing users data.');
            }
        };

        fetchUsers();
    }, []);

    const handleAddUser = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch('/api/add-user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(newUser),
        });

        if (!response.ok) {
            console.error('Failed to add user:', response.statusText);
            setError('Failed to add user.');
            return;
        }

        try {
            const addedUser = await response.json();
            setUsers([...users, addedUser]);
            setShowAddUserForm(false);
            setNewUser({ name: '', email: '', role: '' });
        } catch (err) {
            console.error('Error adding user:', err);
            setError('Error adding user.');
        }
    };

    const handleDeleteUser = async (userId) => {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/delete-user/${userId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            console.error('Failed to delete user:', response.statusText);
            setError('Failed to delete user.');
            return;
        }

        setUsers(users.filter(user => user.id !== userId));
    };

    return (
        <div className="manage-users-container">
            <div className="heading-bar">
                <h3 className="page-title">Manage Users</h3>
            </div>

            {error && <p className="error-text">{error}</p>}

            <table className="users-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.id}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            <td>
                                <Button
                                    variant="danger"
                                    onClick={() => handleDeleteUser(user.id)}
                                    className="delete-user-button"
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="add-user-container">
                <Button
                    variant="primary"
                    onClick={() => setShowAddUserForm(!showAddUserForm)}
                    className="add-user-button"
                >
                    {showAddUserForm ? 'Hide Form' : 'Add User'}
                </Button>

                {showAddUserForm && (
                    <div className="add-user-form">
                        <Form style={styles.form}>
                            <Form.Group controlId="formName">
                                <Form.Label></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newUser.name}
                                    onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                                    placeholder="Enter name"
                                    style={styles.input}
                                />
                            </Form.Group>

                            <Form.Group controlId="formEmail">
                                <Form.Label></Form.Label>
                                <Form.Control
                                    type="email"
                                    value={newUser.email}
                                    onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                                    placeholder="Enter email"
                                    style={styles.input}
                                />
                            </Form.Group>

                            <Form.Group controlId="formRole">
                                <Form.Label>Select Role</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={newUser.role}
                                    onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                                    style={styles.input}
                                >
                                    <option value="">Select role</option>
                                    <option value="admin">Admin</option>
                                    <option value="user">User</option>
                                </Form.Control>
                            </Form.Group>

                            <Button variant="primary" onClick={handleAddUser} style={styles.button}>
                                Add User
                            </Button>
                        </Form>
                    </div>
                )}
            </div>
        </div>
    );
};

const styles = {
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    input: {
        backgroundColor: '#ecf0f1',
        border: '1px solid #1abc9c',
        borderRadius: '4px',
        padding: '15px',
        marginBottom: '20px',
        color: '#2c3e50',
        fontSize: '16px',
        width: '80%',
    },
    button: {
        backgroundColor: '#61acb4',
        border: 'none',
        borderRadius: '4px',
        padding: '15px',
        color: 'white',
        fontSize: '16px',
        cursor: 'pointer',
        marginTop: '10px',
    },
};

export default ManageUsers;
