import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import './ManageBaseStations.css';

function ManageBaseStations() {
    const [baseStations, setBaseStations] = useState([]);
    const [newBaseStation, setNewBaseStation] = useState({ name: '', latitude: '', longitude: '', height: 30 });
    const [message, setMessage] = useState('');
    const [editMode, setEditMode] = useState(null); // Track if editing an existing station
    const [uploadMessage, setUploadMessage] = useState('');
    const token = localStorage.getItem('token');
    const companyId = localStorage.getItem('companyId');  // Retrieve company_id from localStorage

    useEffect(() => {
        fetchBaseStations(token, companyId);
    }, [token, companyId]);

    const fetchBaseStations = async (token, companyId) => {
        try {
            const response = await fetch(`/api/get-base-points`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'company-id': companyId,  // Send company_id in headers if needed
                },
            });
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Failed to fetch base stations:', errorText);
                setMessage('Error fetching base stations.');
                return;
            }
            const data = await response.json();
            setBaseStations(data);
        } catch (error) {
            console.error('Error fetching base stations:', error);
            setMessage('Error fetching base stations.');
        }
    };

    const handleKMZUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('kmzFile', e.target.files[0]);

        try {
            const response = await fetch('/api/upload-kmz', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'company-id': companyId,  // Include company_id
                },
                body: formData,
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Failed to upload KMZ file:', errorText);
                setUploadMessage(`Failed to upload KMZ file: ${errorText}`);
                return;
            }

            setUploadMessage('KMZ file uploaded and processed successfully.');
            fetchBaseStations(token, companyId);
        } catch (error) {
            console.error('Error uploading KMZ file:', error);
            setUploadMessage('Error uploading KMZ file.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewBaseStation({ ...newBaseStation, [name]: value });
    };

    const handleAddBaseStation = async () => {
        try {
            const response = await fetch('/api/add-base-point', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(newBaseStation),
            });
            if (!response.ok) {
                console.error('Failed to add base station:', response.statusText);
                setMessage('Error adding base station.');
                return;
            }
            const result = await response.json();
            setMessage(result.message);
            fetchBaseStations(token, companyId);
            setNewBaseStation({ name: '', latitude: '', longitude: '', height: 30 });
        } catch (error) {
            console.error('Error adding base station:', error);
            setMessage('Error adding base station.');
        }
    };

    const handleDeleteBaseStation = async (id) => {
        try {
            const response = await fetch(`/api/delete-base-point/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                console.error('Failed to delete base station:', response.statusText);
                setMessage('Error deleting base station.');
                return;
            }
            setMessage('Base station deleted successfully.');
            fetchBaseStations(token, companyId);
        } catch (error) {
            console.error('Error deleting base station:', error);
            setMessage('Error deleting base station.');
        }
    };

    const handleEditBaseStation = (station) => {
        // Populate the form fields with the selected station's data
        setNewBaseStation({ 
            name: station.name,
            latitude: station.latitude,
            longitude: station.longitude,
            height: station.height
        });
        setEditMode(station); // Set the editMode to the current station being edited
    };

    const handleUpdateBaseStation = async () => {
        try {
            const response = await fetch(`/api/edit-base-point/${editMode.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(newBaseStation),
            });
            if (!response.ok) {
                console.error('Failed to update base station:', response.statusText);
                setMessage('Error updating base station.');
                return;
            }
            setMessage('Base station updated successfully.');
            setEditMode(null);
            setNewBaseStation({ name: '', latitude: '', longitude: '', height: 30 });
            fetchBaseStations(token, companyId);
        } catch (error) {
            console.error('Error updating base station:', error);
            setMessage('Error updating base station.');
        }
    };

    return (
        <div className="manage-base-stations-container">
            <div className="heading-bar">
                <h3 className="page-title">Manage Base Stations</h3>
            </div>

            {message && <p className="message-text">{message}</p>}
            <p>Upload KMZ</p>
            <input type="file" onChange={handleKMZUpload} className="upload-kmz" />
            {uploadMessage && <p className="upload-message">{uploadMessage}</p>}

            {baseStations.length === 0 ? (
                <p>No base stations found. Please add a new base station.</p>
            ) : (
                <table className="base-stations-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Latitude</th>
                            <th>Longitude</th>
                            <th>Height</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {baseStations.map((station) => (
                            <tr key={station.id}>
                                <td>{station.name}</td>
                                <td>{station.latitude}</td>
                                <td>{station.longitude}</td>
                                <td>{station.height}</td>
                                <td>
                                    <Button variant="warning" onClick={() => handleEditBaseStation(station)}>
                                        Edit
                                    </Button>
                                    <Button variant="danger" onClick={() => handleDeleteBaseStation(station.id)}>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            <div className="add-base-station-container">
                <Button
                    variant="primary"
                    onClick={editMode ? handleUpdateBaseStation : handleAddBaseStation}
                    className="add-base-station-button"
                >
                    {editMode ? 'Update Station' : 'Add Station'}
                </Button>

                <div className="add-base-station-form">
                    <Form>
                        <Form.Group controlId="formName">
                            <Form.Label>Station Name   </Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={newBaseStation.name}
                                onChange={handleInputChange}
                                placeholder="Enter station name"
                                style={styles.input}
                            />
                        </Form.Group>

                        <Form.Group controlId="formLatitude">
                            <Form.Label>Latitude   </Form.Label>
                            <Form.Control
                                type="text"
                                name="latitude"
                                value={newBaseStation.latitude}
                                onChange={handleInputChange}
                                placeholder="Enter latitude"
                                style={styles.input}
                            />
                        </Form.Group>

                        <Form.Group controlId="formLongitude">
                            <Form.Label>Longitude  </Form.Label>
                            <Form.Control
                                type="text"
                                name="longitude"
                                value={newBaseStation.longitude}
                                onChange={handleInputChange}
                                placeholder="Enter longitude"
                                style={styles.input}
                            />
                        </Form.Group>

                        <Form.Group controlId="formHeight">
                            <Form.Label>Height AGL  </Form.Label>
                            <Form.Control
                                type="text"
                                name="height"
                                value={newBaseStation.height}
                                onChange={handleInputChange}
                                placeholder="Enter height"
                                style={styles.input}
                            />
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </div>
    );
}

const styles = {
    input: {
        backgroundColor: '#ecf0f1',
        border: '1px solid #1abc9c',
        borderRadius: '4px',
        padding: '15px',
        marginBottom: '20px',
        color: '#2c3e50',
        fontSize: '16px',
        width: '80%',
    },
};

export default ManageBaseStations;
