import React from 'react';

const PreviousResults = () => {
    return (
        <div style={styles.container}>
            <h2>Previous Results</h2>
            <p>Your previous results will be displayed here...</p>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        textAlign: 'center',
    },
};

export default PreviousResults;
